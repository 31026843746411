import * as React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LayoutContainer from "../components/layout-container";
import HeaderBio from "../components/header-bio";
import { Grid, makeStyles } from '@material-ui/core';
import { LoadIndexPostsQuery } from '../../graphql-types';
import PostListPost from '../components/post-list-post';

const useStyles = makeStyles({
  postList: {
    marginTop: '2em',
  },
});

interface IndexProps {
  data: LoadIndexPostsQuery;
}

const Index: React.FC<IndexProps> = ({data}) => {

  const {t} = useTranslation()
  const classes = useStyles();

  return (
    <Layout>
      <Seo title={t("home")}/>
      <HeaderBio/>
      <LayoutContainer>

        <div className={classes.postList}>
          <Grid container spacing={3}>
            {data.allFile.nodes.map(({ childMdx}) => (
              <Grid item key={childMdx.frontmatter.slug} xs={12} sm={6} md={4}>
                <PostListPost post={childMdx}/>
              </Grid>
            ))}
          </Grid>
        </div>
      </LayoutContainer>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query loadIndexPosts($locale: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        childMdx: { 
          fields: { locale: { eq: $locale } }
          frontmatter: { draft: { ne: true} }
        }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          frontmatter {
            slug
            title
            tags
            description
            date(formatString: "L", locale: $locale)
          }
        }
      }
    }
  }
`
