import * as React from "react"

import { Mdx } from '../../graphql-types';
import { Card, CardActions, CardContent, makeStyles } from '@material-ui/core';
import { LocalizedLink } from 'gatsby-theme-i18n';
import PostTagsList from './post-tags-list';

export interface PostListPostProps {
  post?: Partial<Mdx>
}

const useStyles = makeStyles({
  title: {
    margin: '0 0 0.5em',
    color: 'var(--color-text)'
  },
  description: {
    opacity: 0.8,
  },
  link: {
    textDecoration: 'none',
  },
  postInfo: {
    opacity: 0.7,
    marginLeft: '8px',
  },
  tags: {
    overflow: 'hidden',
    maxHeight: '1.8em',
  }
});

const PostListPost: React.FC<PostListPostProps> = ({post}) => {

  const classes = useStyles();

  return (
    <LocalizedLink to={post.frontmatter.slug} className={classes.link}>
      <Card>
        <CardContent>
          <h2 className={classes.title}>
            {post?.frontmatter?.title}
          </h2>

          <div className={classes.tags}>
            <PostTagsList post={post}/>
          </div>

          <div className={classes.description}>
            {post?.frontmatter?.description}
          </div>

        </CardContent>
        <CardActions>
          <div className={classes.postInfo}>
            <div>{ post.frontmatter.date }</div>
          </div>
        </CardActions>
      </Card>
    </LocalizedLink>
  );
}

export default PostListPost;
